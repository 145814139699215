import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import Button from '@atlaskit/button/standard-button';

import { SSRMouseEventWrapper, SSR_WATCH_BUTTON_METRIC } from '@confluence/browser-metrics';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.025', '2px'),
	},
});

export const WatchButtonPlaceholder = ({ compact }) => (
	<SSRMouseEventWrapper metricName={SSR_WATCH_BUTTON_METRIC} isPlaceholder>
		<StyledButton
			testId="watch-button-placeholder"
			appearance="subtle"
			iconBefore={<WatchIcon label="WatchIcon" />}
			isDisabled
			spacing={compact ? 'compact' : 'default'}
		/>
	</SSRMouseEventWrapper>
);

WatchButtonPlaceholder.propTypes = {
	compact: PropTypes.bool,
};
