import React, { cloneElement } from 'react';
import idx from 'idx';

import { useCallbackWithAnalytics } from '@atlaskit/analytics-next';

const noop = () => {};
export function WebItemAnalyticsWrapper({
	children,
	analyticsData,
	innerRef,
	wrapChildren = false,
}: {
	children: React.ReactElement;
	analyticsData?: object;
	innerRef?: React.Ref<any>;
	wrapChildren?: boolean;
}) {
	const handler = idx(children, (_) => _.props.onClick) || noop;
	const handleClick = useCallbackWithAnalytics(handler, analyticsData!);
	if (wrapChildren) {
		return (
			<span ref={innerRef} onMouseDown={handleClick}>
				{children}
			</span>
		);
	}
	return cloneElement(children, {
		onClick: handleClick,
		ref: innerRef,
	});
}
