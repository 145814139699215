export enum AutomationDiscoveryFeatureFlags {
	MANUAL_TRIGGER = 'confluence.frontend.automation.discover.manual-trigger',
	SHARE_SLACK = 'confluence.frontend.share.slack.automation.discoverability',
}

export enum AutomationDiscoveryFeatureGates {
	CONTEXTUAL_PROMPT_INSIGHTS = 'a4c_contextual_prompts_insights',
}

export enum AutomationExperiments {
	BULK_CHANGE_PAGE_OWNER = 'confluence_frontend_bulk_change_page_owner',
	PRODUCTIVITY_INSIGHTS = 'cfe_automation_contextual_discovery_insights',
}

export enum BulkChangePageOwnerExperimentGroups {
	CONTROL = 'control',
	IN_CONTEXT = 'in-context',
	MODAL = 'modal',
}

export enum ProductivtyInsightsExperimentParams {
	IS_ENABLED = 'isEnabled',
}
