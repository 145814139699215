import React, { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';
import fg from '@atlaskit/feature-gate-js-client';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	AUTOMATION_SHORTCUT,
} from '@confluence/shortcuts';

import type { TriggerProps } from '../../types';

import { AutomationMenuButtonWithSmartButtonSpotlight } from './AutomationMenuButtonWithSmartButtonSpotlight';
import { AutomationMenuButtonWithSpotlight } from './AutomationMenuButtonWithSpotlight';

export const i18n = defineMessages({
	automationGroupedPageButtonsTooltip: {
		id: 'automation-menu.automation-grouped-page-button.tooltip',
		description:
			'The tooltip message that appears when a user hovers on the button for Automation.',
		defaultMessage: 'Automation',
	},
});

export type AutomationGroupedPageButtonProps = TriggerProps;

export const AutomationGroupedPageButton = forwardRef<
	HTMLElement,
	AutomationGroupedPageButtonProps
>(({ isSelected, onClick }: AutomationGroupedPageButtonProps, ref) => {
	const isSmartButtonChangeboardingEnabled = fg.checkGate('cc_a4c_smart_button_changeboarding');
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickWithAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'automationGroupedPageButton',
				source: 'viewPageScreen',
			},
		}).fire();
		onClick();
	};

	const tooltipContent = (
		<ShortcutVisualizer
			shortcut={AUTOMATION_SHORTCUT}
			contentBefore={formatMessage(i18n.automationGroupedPageButtonsTooltip)}
		/>
	);

	return (
		<>
			<Tooltip content={tooltipContent} position="bottom">
				{isSmartButtonChangeboardingEnabled ? (
					<AutomationMenuButtonWithSmartButtonSpotlight
						isSelected={isSelected}
						onClick={onClick}
						onClickWithAnalytics={onClickWithAnalytics}
						ref={ref}
					/>
				) : (
					<AutomationMenuButtonWithSpotlight
						isSelected={isSelected}
						onClick={onClick}
						onClickWithAnalytics={onClickWithAnalytics}
						ref={ref}
					/>
				)}
			</Tooltip>

			<GeneralShortcutListener accelerator={AUTOMATION_SHORTCUT} listener={onClickWithAnalytics} />
		</>
	);
});
