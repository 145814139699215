export const toggleCommentOldRenderer = (e: KeyboardEvent, handleCommentButtonClick) => {
	if (document.getElementById('inline-comment-sidebar')) {
		const activeHighlight = document.querySelector('.active-highlight') as HTMLElement;
		if (activeHighlight) {
			activeHighlight.click();
		}
	} else {
		handleCommentButtonClick(e);
	}
};

export const toggleCommentEditor = (editorEmitter, handleCommentButtonClick) => {
	if (document.getElementById('editor-comments-sidebar')) {
		editorEmitter.emit('setselectedannotation', '');
	} else {
		handleCommentButtonClick();
	}
};
