import React from 'react';

// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import {
	VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import type { RestrictionsDialogTriggerProps } from '../types';

import { RestrictionsDialogTrigger } from './RestrictionsDialogTrigger';

export const RestrictionsDialogTriggerWrapper = (props: RestrictionsDialogTriggerProps) => {
	const { accessStatus } = useSessionData();
	return (
		<ErrorBoundary
			attribution={Attribution.PERMISSIONS_EXPERIENCE}
			key="restrictions-dialog-trigger"
		>
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem }: HeaderItemsContainer) => {
					if (
						accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
						accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS
					) {
						addItem(HEADER_ITEM_KEYS.PAGE_RESTRICTIONS);
						return (
							<>
								<ExperienceStart
									key="restriction-button-start"
									id={props.contentId}
									name={VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE}
								/>
								<RestrictionsDialogTrigger markItemAsLoaded={markItem} {...props} />
							</>
						);
					}
					return null;
				}}
			</Subscribe>
		</ErrorBoundary>
	);
};
